<template>
    <div class="content-box">
        <Title/>

        <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal">
            <el-menu-item
            :index="String(index + 1)"
            v-for="(i, index) of dataNav"
            :key="index"
            @click="handleSelect(index)">
            {{i.label}}</el-menu-item>
        </el-menu>

        <div class="mobile-select">
            <el-select
            v-model="name"
            @change="handleSelect2">
                <el-option
                v-for="item in dataNav"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>

        <Textlist/>
    </div>
</template>

<script>
export default {
    name: "comments",
    data() {
        return {
            activeIndex: '1',
            name: '服',
            dataNav: [
                {
                    value: '服',
                    label: '服装'
                },
                {
                    value: '融资',
                    label: '融资'
                },
            ],
            params: {
                json: 0,
            }
        }
    },
    created() {
        this.$store.commit('textparams', this.params)
        this.$store.commit('changeKeys', this.dataNav[0].value + ' 融资')
        this.$store.dispatch('getText')
    },
    methods: {
        handleSelect(index) {
            this.$store.commit('changeKeys', this.dataNav[index].value + ' 融资')
            this.$store.dispatch('getText')
        },
        handleSelect2(value) {
            this.$store.commit('changeKeys', value + ' 融资')
            this.$store.dispatch('getText')
        },
    }
}
</script>